import React from 'react';
import './styles/Footer.css';

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footerContainer'>
      <div className='footerRow'>
        <div className='footerLineContainer'>
          <div className='footerLine'>
          </div>
        </div>
        <div className='footerContent'>
          <h4>&#169; Fahrschule Krokos</h4>
        </div>
        <div className='footerLineContainer'>
          <div className='footerLine'>
          </div>
        </div>
      </div>
      <div className='footerRow2'>
        <div className='footerContent'>
          <div className='footerLinks'>
            <Link to='/datenschutz'>Datenschutz</Link>
            <Link to='/impressum'>Impressum</Link>
            <h4>Die Fahrschule</h4>
            <Link to='/die-fahrschule/nagold'>Nagold</Link>
            {/* <Link to='/die-fahrschule/haiterbach'>Haiterbach</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
