import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// pages
import PublicRoute from './components/common/PublicRoute';
import Home from './components/containers/Home';
import News from './components/containers/News';
import Contact from './components/containers/Contact';
import Imprint from './components/containers/Imprint';
import DataProtection from './components/containers/DataProtection';
import About from './components/containers/About';
import ErrorPage from './components/containers/ErrorPage';

const browserHistory = createBrowserHistory();

class App extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      slider: { offsetHeight: 100 },
      menuOpen: false
    }
  }

  componentDidMount() {
    window.addEventListener('load', () => { console.log('loaded') });

    const slider: HTMLElement | null = document.getElementById('homeSlider');
    if (slider) this.setState({ slider });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const slider = document.getElementById('homeSlider');
    //@ts-ignore
    if (slider && prevState.slider !== this.state.slider) this.setState({ slider });
  }

  triggerMenu() {
    this.setState({
      //@ts-ignore
      menuOpen: !this.state.menuOpen
    });
  }

  render() {
    return (
      <div>
        {/*@ts-ignore*/}
        <Router history={browserHistory}>
          <Switch>
            <PublicRoute exact path='/'
              component={Home}
              history={browserHistory}
              appState={this.state}
              menuClicked={() => this.triggerMenu()} />

            <PublicRoute path='/neuigkeiten'
              component={News}
              appState={this.state}
              menuClicked={() => this.triggerMenu()} />

            <PublicRoute path='/kontakt'
              component={Contact}
              appState={this.state}
              menuClicked={() => this.triggerMenu()} />

            <PublicRoute path='/impressum'
              component={Imprint}
              appState={this.state}
              menuClicked={() => this.triggerMenu()} />

            <PublicRoute path='/datenschutz'
              component={DataProtection}
              appState={this.state}
              menuClicked={() => this.triggerMenu()} />

            <PublicRoute path='/die-fahrschule/:location'
              component={About}
              appState={this.state}
              menuClicked={() => this.triggerMenu()} />

            <PublicRoute path='*' appState={this.state} component={ErrorPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;