import React from 'react';
import { Link } from 'react-router-dom';

import './styles/NewsFrame.css';

import Button from './../controls/Button';
import SectionHeading from './SectionHeading';

interface NewsFrameProps {
  title: string,
  content: string
}

const NewsFrame = ({ title, content }: NewsFrameProps) => {
  return (
    <div className='newsFrameContainer'>
      <div className='newsFrameItem'>
        <SectionHeading caption='Top Aktuell' />
      </div>
      <div className='newsFrameItem'>
        <h2>{title}</h2>
      </div>
      <div className='newsFrameItem'>
        <p>{content}</p>
      </div>
      <div className='newsFrameItem'>
        <Link to='/neuigkeiten'>
          <Button buttonText='Mehr erfahren' />
        </Link>
      </div>
    </div>
  );
}

export default NewsFrame;