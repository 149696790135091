import React from 'react';
import { Route } from 'react-router-dom';

import StandardLayout from './StandardLayout';

interface PublicRouteProps {
  exact?: boolean,
  path?: any,
  history?: any,
  component: React.ComponentClass<any>|React.SFC,
  menuClicked?: any,
  rest?: any,
  appState: any
}

const PublicRoute = ({ component: Component, menuClicked, ...rest }: PublicRouteProps) => {
  return (
    <Route {...rest} render={(props: any) => {
      //@ts-ignore
      return (<StandardLayout appState={rest.appState} locationPathname={rest.location.pathname} menuClicked={menuClicked} >
        {/* @ts-ignore */}
        <Component {...props} />
      </StandardLayout>)
    }} />
  );
}

export default PublicRoute;