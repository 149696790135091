import React from 'react';
import './styles/About.css'

// thumb images
import thumb1 from './../../assets/img/about/thumb_1.jpg';
import thumb2 from './../../assets/img/about/thumb_2.jpg';
import thumb3 from './../../assets/img/about/thumb_3.jpg';

import ImagePopup from '../common/ImagePopup';

class About extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedDrivingSchool: this.props.match.params.location,
      imagePopUpVisible: false,
      selectedImageUrl: ''
    }

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    console.log(`selected image: ${this.state.selectedImageUrl}`);
    if (prevState.selectedDrivingSchool !== this.props.match.params.location) this.setState({ selectedDrivingSchool: this.props.match.params.location });
  }

  switchImage(url: string) {
    this.setState({
      imagePopUpVisible: true,
      selectedImageUrl: url
    });
  }

  render() {
    const contentNagold = <div className='aboutContainer'>
      <div className='aboutWrapping'>
        <h1>Wirf einen Blick auf unsere Räumlichkeiten...</h1>
        <div className='aboutPicturesContainer'>
          <img src={thumb1} alt='thumb1' onClick={() => {
            this.switchImage('https://fahrschule-krokos.de/img/fs-nagold-1.jpg');
          }} />
          <img src={thumb2} alt='thumb2' onClick={() => {
            this.switchImage('https://fahrschule-krokos.de/img/fs-nagold-2.jpg');
          }} />
          <img src={thumb3} alt='thumb3' onClick={() => {
            this.switchImage('https://fahrschule-krokos.de/img/fs-nagold-3.jpg');
          }} />
        </div>

        <h1>Hier findest du uns:</h1>
        <div className='addressInfo'>
          <b>Fahrschule Krokos</b><br />
          Marktstr. 55<br />
          72202 Nagold<br /><br />
          <i>Büro/Anmeldung:</i><br />
          Montag - Freitag: 17:30 - 18:30<br />
          Freitag: 11:00 - 13:30
          <br /><br />
          <i>Theorieunterricht</i><br />
          Dienstag + Donnerstag: 18:30 - 20:00
        </div>

        <div id='mapContainer'>
          <iframe
            title={`Fahrschule Krokos - Anfahrt`}
            width='100%' height='600'                 
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=%20Marktstr.%2055%2C%2072202%20Nagold+(Fahrschule%20Miro's%20Krokos)&amp;ie=UTF8&amp;t=&amp;z=17&amp;iwloc=B&amp;output=embed"
            frameBorder='0' scrolling='no'>
          </iframe>
          <br /><br />
        </div>
      </div>
    </div>

    return (
      <div id='aboutMain'>

        <ImagePopup
          imgUrl={this.state.selectedImageUrl}
          visible={this.state.imagePopUpVisible}
          closePopup={() => this.setState({ imagePopUpVisible: false, selectedImageUrl: '' })} />

        {contentNagold}
      </div>
    );
  }
}

export default About;