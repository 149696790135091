import React from 'react';
import './styles/Contact.css';
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      indication: true,
      customerName: '',
      customerMail: '',
      customerPhone: '',
      customerMsg: '',
      validCaptcha: false,
      captchaToken: '',
      success: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  sendMail() {
    var http = new XMLHttpRequest();
    var url = 'https://fahrschule-krokos.de/mail.php';
    var params = `name=${this.state.customerName}&mail=${this.state.customerMail}&phone=${this.state.customerPhone}&message=${this.state.customerMsg}&token=${this.state.captchaToken}`;
    http.open('POST', url, true);
    
    //Send the proper header information along with the request
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    
    http.onreadystatechange = function() {//Call a function when the state changes.
        if(http.readyState == 4 && http.status == 200) {
            alert(http.responseText);
        }
    }
    http.send(params);
  }

  render() {
    return (
      <div id='contactMain'>
        <div id='contactContainer'>

          <div className='contactRow'>
            <div className='contactHeader'>
              <div>
                <span>Tritt mit uns</span>
              </div>
              <div>
                <span className='contactHighlight contactHightlight2'>in Kontakt</span>
              </div>
              <div>
                <span>über das anliegende</span>
              </div>
              <div>
                <span className='contactHighlight'>Kontaktformular</span>
              </div>
            </div>

            <div className='contactRow'>
              <div className="arrow bounce">
                <i className="material-icons">keyboard_arrow_down</i>
              </div>
            </div>

            <div className='contactColumn'>
              <form id='contactForm'>
                <h4>Dein Vor- und Nachname</h4>
                <input placeholder='Gib hier deinen Vor - und Nachnamen ein' className='conactInput'
                  onChange={(e: any) => this.setState({ customerName: e.target.value })}
                  value={this.state.customerName} />
                <h4>Deine Email</h4>
                <input placeholder='Hier gibst du deine Email ein' className='conactInput' type='email'
                  onChange={(e: any) => this.setState({ customerMail: e.target.value })}
                  value={this.state.customerMail} />
                <h4>Deine Handynummer</h4>
                <input placeholder='Deine Handynummer' className='conactInput' type='phone'
                  onChange={(e: any) => this.setState({ customerPhone: e.target.value })}
                  value={this.state.customerPhone} />
                <h4>Deine Nachricht</h4>
                <textarea placeholder='Hier kannst du deine Nachricht für deine Anfrage verfassen' className='conactInput' rows={5}
                  onChange={(e: any) => this.setState({ customerMsg: e.target.value })}
                  value={this.state.customerMsg} />
                <ReCAPTCHA
                  sitekey='6Leu9J8fAAAAAO9xM40LBjgheHLJf7lzd_Gq9A2g'
                  // sitekey='6LfESXocAAAAAJwjzn8rmKsQjTD7M8q2WBO_y_3U'
                  size="compact"
                  onChange={(token: any) => {
                    let valid = false;
                    if (token && token.length > 0) {
                      valid = true;
                    }
                    this.setState({ captchaToken: token, validCaptcha: valid });
                  }}
                />
                <input className={`contactButton${this.state.validCaptcha ? ' contactButtonValid' : ''}`} type='button' value='Senden' onClick={() => {
                  if (this.state.validCaptcha)
                    this.sendMail();
                }} />
              </form>
            </div>
          </div>

          <div style={{ textAlign: 'center', marginBottom: '50px' }}>
            <h4>Oder melde dich bei uns telefonisch/per Mail:</h4>
            <a href='mailto:info@fahrschule-krokos.de'>info@fahrschule-krokos.de</a><br />
            Artur: +49 (0) 173/3010954
          </div>

        </div>
      </div>
    );
  }
}

export default Contact;