import React from 'react';
import './styles/FAQSection.css';

interface FAQSectionProps {
  caption: string,
  content: Array<any>
}

const FAQSection = ({ caption, content }: FAQSectionProps) => {
  return (
    <div className='fAQSectionContainer'>
      <h4>{caption}</h4>
      <ul>
        {
          content.map((item: any) => {
            return <li key={item}>{item}</li>
          })
        }
      </ul>
      <div className='fAQSectionSplitter'></div>
    </div>
  );
}

export default FAQSection;