import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Header.css'
import logoUrl from '../../assets/img/logo_v2.jpg';

interface HeaderProps {
  onMenuBarClicked: any,
  menuOpen: boolean,
  locationPathname: string
}

const Header = ({ onMenuBarClicked, menuOpen, locationPathname }: HeaderProps) => {
  return (
    <header id='headerContainer'>

      <Link to='/' className='headerLogoLink'>
        <img className='headerLogo' src={logoUrl} height="100%" alt='logo' />
      </Link>

      <nav>
        <ul>
          <li><Link to='/'>Startseite</Link></li>
          <li><Link to='/neuigkeiten'>Neuigkeiten</Link></li>
          <li id='dropdown-link'>
            <span>Die Fahrschule <i className="material-icons headerCustomMaterialIcons">keyboard_arrow_down</i></span>
            <div className='dropdown-container'>
              <div className='dropdown-content'>
                <ul>
                  <Link to='/die-fahrschule/nagold'><li>Nagold</li></Link>
                  {/* <Link to='/die-fahrschule/haiterbach'><li>Haiterbach</li></Link> */}
                </ul>
              </div>
            </div>
          </li>
          <li><Link to='/kontakt'>Kontakt</Link></li>
        </ul>
      </nav>
      
      <div onClick={onMenuBarClicked} className='headerMobileNav'>
        <div className='headerMenuStripe'></div>
        <div className='headerMenuStripe'></div>
        <div className='headerMenuStripe'></div>
      </div>

      <div id='headerMobileMenu' className={menuOpen ? 'headerMobileMenuActive' : ''}>
        <ul>
          <li><Link to='/' onClick={onMenuBarClicked}>Startseite</Link></li>
          <li><Link to='/neuigkeiten' onClick={onMenuBarClicked}>Neuigkeiten</Link></li>
          <li><Link to='/die-fahrschule/nagold' onClick={onMenuBarClicked}>Die Fahrschule in Nagold</Link></li>
          <li><Link to='/kontakt' onClick={onMenuBarClicked}>Kontakt</Link></li>
        </ul>
      </div>
    </header>
  );
}

export default Header;