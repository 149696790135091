import React from 'react';
import './styles/ProgressIndicator.css';

import SectionHeading from './SectionHeading';

const ProgressIndicator = () => {
  return (
    <div id='progressIndicatorContainer'>
      <div className='progressIndicatorRow'>
        <div className='progressIndicatorCaption'>
          <SectionHeading caption='Der einfache Weg ans Ziel' />

          <p>Mit dem einfachen Lernweg den dir Krokos aufzeigt kommst auch du rasch ans Ziel</p>
        </div>
      </div>
      <div className='progressIndicatorRow'>
        <div className='progressIndicatorIndicator'>
          <i className="material-icons">school</i>
        </div>
        <div className='progressIndicatorStripeContainer'>
          <div className='progressIndicatorStripe'>
          </div>
        </div>
        <div className='progressIndicatorIndicator'>
          <i className="material-icons">supervisor_account</i>
        </div>
        <div className='progressIndicatorStripeContainer'>
          <div className='progressIndicatorStripe'>
          </div>
        </div>
        <div className='progressIndicatorIndicator'>
          <i className="material-icons">drive_eta</i>
        </div>
      </div>

      <div className='progressIndicatorRow'>
        <div className='progressIndicatorDescription'>
          <h2>i. lerne</h2>
        </div>
        <div className='progressIndicatorSpacer'>

        </div>
        <div className='progressIndicatorDescription'>
          <h2>ii. übe</h2>
        </div>
        <div className='progressIndicatorSpacer'>

        </div>
        <div className='progressIndicatorDescription'>
          <h2>iii. leg los</h2>
        </div>

      </div>
    </div>
  );
}

export default ProgressIndicator;