import React from 'react';
import './styles/DrivingLicenseClasses.css';

import SectionHeading from './SectionHeading';

interface DrivingLicenseClassesProps {
  switchDrivingClass: any
}

const DrivingLicenseClasses = ({ switchDrivingClass }: DrivingLicenseClassesProps) => {
  return (
    <div className='drivingLicenseClassesContainer'>
      <div className='drivingLicenseClassesRow'>
        <SectionHeading caption='Die unterschiedlichen Führerscheinklassen' />
      </div>

      <div className='drivingLicenseClassesBoxWrapper'>
        <div
          className='drivingLicenseClassesBox drivingLicenseClassesBoxB'
          onClick={() => switchDrivingClass(1)}>
          <h1>Klasse B, BE, B96, B196, B197</h1>
        </div>
        <div>
          <div
            className='drivingLicenseClassesBox drivingLicenseClassesBoxA'
            onClick={() => switchDrivingClass(2)}>
            <h1>Klasse A, A2, A1</h1>
          </div>
          <div
            className='drivingLicenseClassesBox drivingLicenseClassesBoxAM'
            onClick={() => switchDrivingClass(3)}>
            <h1>Klasse AM</h1>
          </div>
        </div>
        <div>
          <div
            className='drivingLicenseClassesBox drivingLicenseClassesBoxL'
            onClick={() => switchDrivingClass(4)}>
            <h1>Klasse L</h1>
          </div>
          <div
            className='drivingLicenseClassesBox drivingLicenseClassesBoxMofa'
            onClick={() => switchDrivingClass(5)}>
            <h1>Klasse Mofa</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrivingLicenseClasses;