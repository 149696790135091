import React from 'react';
import './styles/TextBox.css';

interface TextBoxProps {
  title: string,
  description: string
}

const TextBox = ({ title, description }: TextBoxProps) => {
  return (
    <div className='textBoxContainer'>
      <h3 className='textBoxHeader'>{title}</h3>
      <p className='textBoxParagraph'>{description}</p>
    </div>
  );
}

export default TextBox;