import React from 'react';
import './styles/StandardLayout.css';

import Header from './Header';
import Footer from './Footer';

interface StandardLayoutProps {
  children: React.ComponentClass<any>,
  appState: any,
  menuClicked: any,
  locationPathname: string
}

const StandardLayout = ({ children, appState, menuClicked, locationPathname }: StandardLayoutProps) => {
  return (
    <div className='standardLayoutContainer'>
      <Header
        menuOpen={appState.menuOpen}
        locationPathname={locationPathname}
        onMenuBarClicked={menuClicked} />

      <div className='standardLayoutContent'>
        {children}
      </div>

      <Footer />
    </div>
  );
}

export default StandardLayout;