import React from 'react';
import './styles/ClassPopup.css';

import { FAQSection } from './';

interface ClassPopupProps {
  visible: boolean,
  closePopup: any,
  classes: Array<any>
}

const ClassPopup = ({ visible, closePopup, classes }: ClassPopupProps) => {
  return (
    <div
      id='drivingClassesPopupOverlay'
      style={{ display: (visible) ? 'block' : 'none' }}
      onClick={closePopup}>
      <div id='drivingClassesPopup' onClick={(event: any) => {
        // prevent popup from closing 
        event.stopPropagation();
        event.cancelBubble = true;
      }}>
        {classes.length > 0 ? classes.map(item => {
          return <div key={item.id} className='drivingClassesColumn'>
            <h2>{item.drivingClassName}</h2>
            <FAQSection
              caption='Was darf ich mit dieser Klasse alles fahren?'
              content={item.drivable} />

            <FAQSection
              caption='Mindestalter'
              content={item.minimumAge} />

            <FAQSection
              caption='Ausbildung'
              content={item.training} />

            <FAQSection
              caption='Welche Prüfungen muss ich ablegen?'
              content={item.exams} />

            <FAQSection
              caption='Unterlagen und Nachweise'
              content={item.documents} />

            <FAQSection
              caption='Wissenswertes'
              content={item.facts} />
          </div>
        })
          : null}
      </div>
    </div>
  );
}

export default ClassPopup;