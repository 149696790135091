import React from 'react';
import './styles/Error.css';

const ErrorPage = () => {  
    return (
      <div className='errorMain'>
        <h1>Error - Page not found!</h1>
      </div>
    );
}

export default ErrorPage;
