import React from 'react';
import './styles/StatisticsIndicator.css';

const StatisticsIndicator = () => {

  return (
    <div id='statisticsIndicatorMain'>
      <div className='statisticsIndicatorHeader'>
        <span className='statisticsIndicatorCaption'>Welche Klassen wir ausbilden...</span>
      </div>
      <div className='statisticsIndicatorContainer'>
        <div className='statisticsIndicatorStaticsBox'>
          <h1>Das klassische<br />Auto</h1>
          <div className='statisticsIndicatorSpacer'></div>
          <h3>Klasse(n) B, BE, B96, B196, B197</h3>
        </div>        
        <div className='statisticsIndicatorStaticsBox'>
          <h1>Flott auf zwei<br />Rädern unterwegs</h1>
          <div className='statisticsIndicatorSpacer'></div>
          <h3>Klasse A, A1, A2</h3>
        </div>       
        <div className='statisticsIndicatorStaticsBox'>
          <h1>Klein und<br/>spritzig</h1>
          <div className='statisticsIndicatorSpacer'></div>
          <h3>Klasse AM, Mofa</h3>
        </div>
        <div className='statisticsIndicatorStaticsBox'>
          <h1>Wenns um's<br/>Grobe gehen soll</h1>
          <div className='statisticsIndicatorSpacer'></div>
          <h3>Klasse L</h3>
        </div>
      </div>
    </div>
  );
}

export default StatisticsIndicator;