import React from 'react';
import './styles/SectionHeading.css';

interface SectionHeadingProps {
  caption: string
}

const SectionHeading = ({ caption }: SectionHeadingProps) => {
  return (
    <div className='sectionHeadingContainer'>
      <div className='sectionHeadingLine'></div>
      <h1>{caption}</h1>
      <div className='sectionHeadingLine'></div>
    </div>
  );
}

export default SectionHeading;