import React from 'react';
import './styles/News.css';

// components
import NewsPost from '../common/NewsPost';

class News extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      news: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    fetch('https://public-api.wordpress.com/rest/v1.1/sites/fahrschulekrokos.wordpress.com/posts?order=DESC')
      .then(res => res.json())
      .then(posts => {
        const alteredPosts = posts.posts.map((post: any) => {
          const regex = /(<([^>]+)>)/ig;
          const tmpDate = `${new Date(post.date).toLocaleDateString('de-DE')} - ${new Date(post.date).toLocaleTimeString('de-DE')}`;
          post.date = tmpDate;
          post.content = post.content.replace(regex, '');
          return post;
        })
        this.setState({
          news: alteredPosts
        })
      })
      .catch(err => console.log(err));
  }

  render() {
    return (
      <div id='newsMain'>
        <div id='newsContainer'>
          <div className='newsContainerRow'>
            <h1>Neuigkeiten</h1>
          </div>
          <div className='newsContainerRow'>
            {this.state.news.length > 0 ? this.state.news.map((item: any) => {
              return <NewsPost {...item} />
            }) : <h3>Neugigkeiten werden geladen...</h3>}
          </div>
        </div>
      </div>
    );
  }
}

export default News;