import React from 'react';
import './styles/ImagePopup.css';

interface ImagePopupProps {
  visible: boolean,
  closePopup: any,
  imgUrl: string
}

const ImagePopup = ({ visible, closePopup, imgUrl }: ImagePopupProps) => {
  return (
    <div
      id='imagePopupOverlay'
      style={{ display: (visible) ? 'block' : 'none' }}
      onClick={closePopup}>
      <div id='imagePopup'>
        <img src={imgUrl} alt={imgUrl} />
      </div>
    </div>
  );
}

export default ImagePopup;