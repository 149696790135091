import React from 'react';
import './styles/ButtonStyle.css';

interface ButtonProps {
  buttonText: string
}

const Button = ({ buttonText }: ButtonProps) => {
  return (
    <div className="button__style__btn">{buttonText}</div>
  );
}

export default Button;