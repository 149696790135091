import React from 'react';
import './styles/UnderlinedText.css';

interface UnderlinedTextProps {
  text: string
}

const UnderlinedText = ({ text }: UnderlinedTextProps) => {
  return (
    <div className='underlinedTextStyle'>
      <h1 style={{ margin: '0 0 10px 0' }}>{text}</h1>
      <div className='underlinedTextLineStyle'></div>
    </div>
  );
}

export default UnderlinedText;