import React from 'react';
import './styles/Imprint.css';

class Imprint extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id='imprintMain'>
        <div className='imprintContainer'>
          <div className='imprintWrapping'>
            <h2>Impressum</h2>
            <p>Angaben gemäß §5 TMG:</p>
            <b>Fahrschule Krokos</b>
            Marktstr. 55<br />
            72202 Nagold<br />

            <b>Geschäftsführer</b>
            Artur Krokos, Telefon: +49 (0) 173/3010954<br />
            <a href='mailto:info@fahrschule-krokos.de'>info@fahrschule-krokos.de</a><br />

            <b>Umsatzsteuer-Id</b>
            Umsatzsteuer-Identifikationsnummer gemäß §27 a<br />
            Umsatzsteuergesetz:<br />
            DE 271 024 952<br /><br />

            <b>Aufsichtsbehörde</b>
            LRA Calw<br />
            Landratsamt Calw<br />
            Vogteistraße 42- 46<br />
            75365 Calw<br /><br />

            <a href='https://www.kreis-calw.de/' target='_blank' rel='noopener noreferrer'>https://www.kreis-calw.de/</a><br />

            <b>Keine Abmahnung ohne vorherigen Kontakt.</b>
            <span>
              Sollte der Inhalt oder die Aufmachung dieser Webseite fremde Rechte Dritter oder gesetzliche Rechte verletzen, so bitten wir um eine entsprechende Nachricht ohne Kostennote. Wir garantieren, dass die zu Recht beanstandeten Passagen unverzüglich entfernt werden, ohne dass von Ihrer Seite die Einschaltung eines Rechtsbeistandes erfordelich ist. Dennoch von Ihnen ohne vorherige Kontaktaufnahme ausgelöste Kosten werden wir vollständig zurückweisen und gegebenfalls
              eine Gegenklage wegen Verletzung vorher genannter Bestimmungen einreichen.
            </span>

            <b>Streitschlichtung</b>
            <span style={{ marginBottom: '20px' }}>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br />
              <a href='https://ec.europa.eu/consumers/odr' target='_blank' rel='noopener noreferrer'>https://ec.europa.eu/consumers/odr</a><br /><br />
              Unsere E-Mail-Adresse finden Sie oben im Impressum.<br />
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.<br /><br />
              <b>Haftung für Inhalte</b><br />
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
              allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
              verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
              forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
              Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br /><br />
              <b>Haftung für Links</b><br />
              Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
              Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
              verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
              Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
              waren zum Zeitpunkt der Verlinkung nicht erkennbar.
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
umgehend entfernen.<br /><br />
              <b>Urheberrecht</b><br />
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
              Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
              Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
              beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br /><br />
              Quelle:<br />
              <a href='https://www.e-recht24.de' target='_blank' rel='noopener noreferrer'>https://www.e-recht24.de</a><br />
            </span>
            <br /><br /><br />
          </div>
        </div>
      </div>
    );
  }
}

export default Imprint;
