import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Home.css';

// components
import Button from '../controls/Button';
import TextBox from '../common/TextBox';
import UnderlinedText from '../common/UnderlinedText';
import StatisticsIndicator from '../common/StatisticsIndicator';
import ProgressIndicator from '../common/ProgressIndicator';
import NewsFrame from '../common/NewsFrame';
import SectionHeading from '../common/SectionHeading';
import DrivingLicenseClasses from '../common/DrivingLicenseClasses';
import ClassPopup from '../common/ClassPopup';

import data from '../../assets/content/home.json';
// drivingClassMapping
/*
 * 1 --> [4, 5, 6]
 * 2 --> [0, 1, 2]
 * 3 --> [3]
 * 4 --> [7]
 * 5 --> [8]
 */

class Home extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      ...data,
      classPopupVisible: false,
      latestNews: {
        title: 'Sorry',
        content: 'Bisher sind noch keine Neuigkeiten erschienen'
      },
      filteredDrivingLicenseClasses: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.switchDrivingClass(1, true);

    fetch('https://public-api.wordpress.com/rest/v1.1/sites/fahrschulekrokos.wordpress.com/posts?order=DESC&number=1')
      .then(res => {
        return res.json();
      }).then(post => {
        this.setState({
          latestNews: {
            title: post.posts[0].title,
            content: post.posts[0].content.replace(/(<([^>]+)>)/ig, '')
          }
        })
      })
  }

  triggerMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  switchDrivingClass(id: number, initial = false) {
    let tmp = [];

    if (id === 1) {
      tmp = this.state.drivingLicenseClasses.filter((item: any) => {
        return item.id === 4 || item.id === 5 || item.id === 6
      });
    }

    if (id === 2) {
      tmp = this.state.drivingLicenseClasses.filter((item: any) => {
        return item.id === 0 || item.id === 1 || item.id === 2
      });
    }

    if (id === 3) {
      tmp = this.state.drivingLicenseClasses.filter((item: any) => {
        return item.id === 3
      });
    }

    if (id === 4) {
      tmp = this.state.drivingLicenseClasses.filter((item: any) => {
        return item.id === 7
      });
    }

    if (id === 5) {
      tmp = this.state.drivingLicenseClasses.filter((item: any) => {
        return item.id === 8
      });
    }

    this.setState({
      activeDrivingClassIndex: id,
      filteredDrivingLicenseClasses: tmp,
      classPopupVisible: initial ? false : !this.state.classPopupVisible
    });
  }

  render() {
    return (
      <div id='homeMain'>

        <ClassPopup
          visible={this.state.classPopupVisible}
          classes={this.state.filteredDrivingLicenseClasses}
          closePopup={() => {
            const body = document.body;
            if (body && body.style) body.style.overflow = 'auto';
            this.setState({ classPopupVisible: !this.state.classPopupVisible });
          }} />

        <div id='homeSlider'>
          <h1>Mit Krokos zum<br />Führerschein</h1>
        </div>

        <div className='homeWrapper'>
          <div className='homeContainer'>
            <div className='homeRow'>
              <NewsFrame {...this.state.latestNews} />
            </div>
          </div>
        </div>

        <div className='homeWrapper'>
          <div className='homeContainer'>
            <div className='homeRow'>
              <SectionHeading caption={`Warum Krokos? Drei gute Gründe`} />
            </div>
            <div className='homeRow homeRowAlternative'>
              {this.state.textBoxes.map((item: any) => {
                return <TextBox key={item.title} {...item} />
              })}
            </div>

            <div className='homeRow'>
              <UnderlinedText text='Du bist neugierig geworden? Kontaktiere uns!' />
            </div>
            <div className='homeRow'>
              <Link to='/kontakt'><Button buttonText='Zum Kontaktformular' /></Link>
            </div>
            <div className='homeRow'>
            </div>
          </div>
        </div>

        <div className='homeWrapper'>
          <div className='homeContainer'>
            <div className='homeRow'>
              <StatisticsIndicator />
            </div>
          </div>
        </div>
        <div className='homeWrapper'>
          <div className='homeContainer'>
            <div className='homeRow'>
              <ProgressIndicator />
            </div>
          </div>
        </div>

        <div className='homeWrapper'>
          <DrivingLicenseClasses            
            switchDrivingClass={(id: number) => {
              const body = document.body;
              if (body && body.style) {
                body.style.overflow = 'hidden';
                body.style.height = '100%';
              }
              this.switchDrivingClass(id);
            }} />
        </div>
      </div>
    );
  }
}

export default Home;
