import React from 'react';
import { Link } from 'react-router-dom';
import './styles/NewsPost.css';

import Button from './../controls/Button';

interface NewsPostProps {
  id: number,
  title: string,
  date: string,
  content: string,
  buttonText: string
}

const NewsPost = ({ id, title, date, content, buttonText }: NewsPostProps) => {
  return (
    <div key={id} className='newsPostContainer'>
      <div className='newsPostHeading'>
        <div className='newsPostLogoWrapper'>
          <div className='newsPostLogo'>
            <h1>kro<br />kos</h1>
          </div>
        </div>
        <div>
          <h2>{title}</h2>
          <p className='newsPostDate'>{date}</p>
        </div>
      </div>
      <div className='newsPostSplitter'></div>
      <p className='newsContent'>{content}</p>
      {buttonText ? <Link to='/kontakt'><Button buttonText={buttonText} /></Link> : null}
    </div>
  );
}

export default NewsPost;